<template>
  <section
    class="lg:pt-32 relative overflow-hidden border-b border-b-neutral-500 border-opacity-20 from-main via-white to-transparent bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] dark:from-grape-800 dark:via-grape-900 dark:to-black before:-z-5 lg:before:z-10 before:content-[''] before:h-1/5 before:bg-gradient-to-t before:from-transparent dark:before:to-black before:top-0 before:absolute before:left-0 before:right-0 before:pointer-events-none"
  >
    <div class="container max-w-7xl mx-auto text-center z-5 relative">
      <header class="max-w-xl md:max-w-2xl mx-auto px-4">
        <h2 class="text-2xl md:text-5xl">
          Build & design custom 24/7<br class="hidden lg:block" />
          live streams for your brand
        </h2>
        <p class="text-md mt-4 text-neutral-500">{{ tabs[currentTab].copy }}</p>
      </header>
      <div class="overflow-auto px-4 w-full mb-4 lg:mb-16">
        <ul
          class="relative p-2 mt-4 lg:mt-10 inline-flex rounded-full flex-nowrap flex-row border bg-neutral-100/50 dark:bg-transparent border-neutral-400 border-opacity-20 z-20 before:bg-white before:rounded-full before:content-[''] before:top-2 before:left-2 before:bottom-2 before:w-24 before:-z-10 before:absolute before:transition-all before:duration-300 before:translate-x-[var(--floater-position)]"
          :style="{ '--floater-position': `${currentTab * 96}px` }"
        >
          <li v-for="(tab, index) in tabs" :key="index" class="block">
            <CtaButton
              class="text-sm w-24 !px-0 !bg-transparent"
              size="small"
              :class="{
                '!text-black': currentTab === index,
                '!text-neutral-600 dark:!text-white': currentTab !== index,
              }"
              @click="switchTab(index)"
            >
              {{ tab.title }}
            </CtaButton>
          </li>
        </ul>
      </div>
    </div>

    <div class="px-4 lg:px-[13%] -mb-[10%] relative">
      <img
        :src="`/images/builder/${tabs[currentTab].title.toLowerCase()}_sm.webp`"
        :srcset="`/images/builder/${tabs[
          currentTab
        ].title.toLowerCase()}_sm.webp 600w, /images/builder/${tabs[
          currentTab
        ].title.toLowerCase()}.webp 1440w`"
        class="rounded-lg object-cover border z-20 relative border-white border-opacity-20 mx-auto"
        alt=""
      />
    </div>
  </section>
</template>

<script setup>
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import { ref } from 'vue'

const currentTab = ref(0)

const switchTab = (index) => {
  currentTab.value = index
}

const tabs = [
  {
    title: 'Music',
    copy: 'Get ready to take your music to the next level with upstream. Build stunning 24/7 live streams with your favorite playlist, design custom visuals, and engage with your fans in real-time. Share your passion for music and create a unique experience.',
  },
  {
    title: 'Gaming',
    copy: 'From thrilling gameplay sessions to engaging commentary, upstream empowers you to take your gaming content to the next level. Connect with fellow gamers, share your expertise, and create a captivating experience that keeps viewers coming back.',
  },
  {
    title: 'Religion',
    copy: 'Strengthen your religiouse community with the power of live streaming. Create immersive 24/7 live streams of religious services, ceremonies or meditations. Engage your congregation and reach a wider audience, sharing hope and positivity.',
  },
  {
    title: 'Business',
    copy: 'Unlock new opportunities for your business with 24/7 live streams. Host webinars and product demonstrations that cater specifically to businesses and professionals. Expand your reach, build industry connections, and showcase your expertise.',
  },
  {
    title: 'News',
    copy: 'Stay ahead of the news game with upstream. Launch your own news channel and deliver real-time updates, interviews, and breaking news to your viewers. Connect with your audience, foster engagement, and become a reliable source of information.',
  },
  {
    title: 'Other',
    copy: "The possibilities are endless with upstream. Whether you're a fitness instructor, a chef, or a travel blogger, you can create a 24/7 live stream that showcases your unique talents and passions. Share your expertise and build a community around your brand.",
  },
]
</script>
