<template>
  <section
    id="reviews"
    class="relative dark:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] dark:from-grape-900 dark:via-grape-900 dark:to-black after:content-[''] after:h-1/4 after:bg-gradient-to-b after:from-transparent after:to-beige dark:after:to-black after:bottom-0 after:absolute after:left-0 after:right-0 after:pointer-events-none border-t border-opacity-10 border-white mt-12 lg:mt-32 pt-8 lg:pt-16 pb-8 lg:pb-0"
  >
    <header class="max-w-md lg:max-w-2xl mx-auto px-4 text-center">
      <h2 class="text-xl lg:text-4xl font-unbounded">
        There's a reason why we're<br />
        rated 5 stars on Trustpilot
      </h2>
      <p class="text-md mt-4 text-neutral-500">
        Customers from all over the world are using Upstream to build their own
        24/7 live streams. Here’s what they have to say about us.
      </p>
    </header>

    <div class="whitespace-nowrap overflow-auto">
      <div
        class="flex 2xl:block 2xl:columns-4 gap-4 pt-10 2xl:pt-16 pb-4 2xl:pb-0 px-4"
      >
        <a
          v-for="review in reviews.slice(6, reviews.length)"
          :key="review.name"
          target="_blank"
          :href="review.url"
          class="block min-w-[320px] xl:w-auto"
        >
          <Card
            class="mb-4 h-full dark:!bg-black hover:!bg-transparent w-full whitespace-normal max-h-56 xl:max-h-full dark:to-black after:content-[''] xl:after:hidden after:h-24 after:bg-gradient-to-b after:from-transparent dark:after:to-black dark:via-black after:bottom-0 after:absolute after:left-0 after:right-0 after:pointer-events-none"
          >
            <h3 class="text-lg font-semibold flex items-center">
              <span
                class="rounded-full uppercase w-10 h-10 mr-4 items-center justify-center flex"
                :class="getRandomColor()"
              >
                {{ getInitials(review.name) }}
              </span>
              {{ review.name }}
            </h3>
            <p class="flex mt-4">
              <svg
                v-for="star in +review.rating"
                :key="star"
                name="Star"
                class="text-wintergreen w-4 h-4 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                fill="currentColor"
              >
                <path
                  d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                />
              </svg>
            </p>
            <p class="mt-4 text-neutral-500 text-sm leading-relaxed">
              {{ review.review.slice(0, 200) }}
              <template v-if="review.review.length > 200">...</template>
            </p>
          </Card>
        </a>
      </div>
    </div>
    <CtaButton
      type="external"
      href="https://www.trustpilot.com/review/upstream.so"
      target="_blank"
      class="!block w-56 mx-auto z-20 relative"
    >
      Read more reviews
    </CtaButton>
  </section>
</template>

<script setup>
import Card from '@/Components/Shared/Elements/Card.vue'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'

const getInitials = (name) => {
  const [first, last] = name.split(' ')
  return `${first[0]}${last ? last[0] : ''}`
}

const colors = [
  'bg-wintergreen text-black',
  'bg-main text-white',
  'bg-grape text-white',
  'bg-citrus text-black',
  'bg-alternate text-black',
  'bg-white text-black',
]
const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)]

// We hardcode reviews so we don't have to hit the API on every page load
// Here's the snippet we use to fetch reviews manually from our Trustpilot page: https://www.trustpilot.com/review/upstream.so
// [...document.querySelectorAll('[data-service-review-card-paper]')].map(node => {
//   const img = node.querySelector("[data-service-review-rating] img").src.split('/').pop().split('.')[0].split('-').pop()
//   return {
//     name: node.querySelector("[data-consumer-name-typography]").innerText,
//     url: node.querySelector("[data-review-title-typography]").href,
//     rating: img,
//     review: node.querySelector("[data-service-review-text-typography]").innerText,
//   }})
const reviews = [
  {
    name: 'MeditatewithOscar',
    url: 'https://www.trustpilot.com/reviews/65a95eed52d6d8ad277d6f37',
    rating: '5',
    review:
      'Upstream is intuitive and easy to use. Customer support responded quickly and was very knowledgeable in resolving my issues. The price is right. I highly recommend this service.',
  },
  {
    name: 'Alyssa Farris',
    url: 'https://www.trustpilot.com/reviews/6499748b4b712ac7584f064b',
    rating: '5',
    review:
      "I love how easy Upstream is to use - especially for someone like me who has no technical knowledge of servers. The whole process of a 24/7 live stream was overwhelming to me until I found upstream. Support is really responsive and it's clear they genuinely care about the platform and it's users.",
  },
  {
    name: 'duncan',
    url: 'https://www.trustpilot.com/reviews/65a154a559d67d168680d32d',
    rating: '5',
    review:
      'Excellent customer service and help. Knowledgeable, quick, efficient and polite. I would give six stars out of five if this were possible. Excellent service overall',
  },
  {
    name: 'Koren Ilor',
    url: 'https://www.trustpilot.com/reviews/65a4d1e7adda1dfd337c833f',
    rating: '5',
    review:
      "I'm so satisfied and want to thank you for the stream quality that I do through your website.",
  },
  {
    name: 'Dave Vicks minds eye design',
    url: 'https://www.trustpilot.com/reviews/6498013ee8bc62bcffae68e3',
    rating: '5',
    review:
      'The team at Upstream is incredibly helpful. The setup for 24/7 streaming is easy to understand, and I love having control over settings, video order, refreshing a stream, and so on. I have tried at least five other services, looking for something as good as Upstream. Good stuff.',
  },
  {
    name: 'Chill Hope',
    url: 'https://www.trustpilot.com/reviews/659469b14cf9eb8b880fc838',
    rating: '5',
    review:
      'Sebastian from upstream support was very kind, helpful and fast. And to be honest I never experienced such a great free service anywhere on internet before so... I have just the best words for that man " Sebastian" what a helper. I highly recommend using Upstream.so. Wish you a happy new 2024 Year. Best regards from ChillHope24 :)',
  },
  {
    name: 'Zeilhan',
    url: 'https://www.trustpilot.com/reviews/65611f0bb17640d32d0e6707',
    rating: '5',
    review:
      'I run a small YouTube channel with less than 100 subscribers but just using the free trial of Upstream got me 30 views (more than I normally get) and as subscriber IN ONE DAY. To Make things better I even got help by the team to setup and a nice discount offer to go premium when my trial expired.\n\nI definitely recommend Upstream. I am going to be making this part of my content strategy too.',
  },
  {
    name: 'Davone',
    url: 'https://www.trustpilot.com/reviews/6577b7971ae5203ef4ab0da7',
    rating: '5',
    review:
      'Super helpful staff if you have any questions. No issues with the platform so far. All minor issues were resolved within the same hour. Their customer service is top notch',
  },
  {
    name: 'Atif',
    url: 'https://www.trustpilot.com/reviews/6560db7ca9551dd1435c223d',
    rating: '5',
    review:
      'Great Experience. Far better than other streaming services like Go Stream, Live Karo etc. because Upstream provide better bitrate for high quality live streaming for Youtube with pretty budget friendly plans.',
  },
  {
    name: 'Anthony',
    url: 'https://www.trustpilot.com/reviews/655d8cfff551f137df5792fd',
    rating: '5',
    review:
      "I'm a new customer to upstream, but have been using vps for the past year. I've had numerous problems with my previous provider and decided to change. My first day contacting support I met Sebastian. I have never has such good customer service in my life! I wish more businesses cared as much about customers as Sebastian. Upstream sets a standard far above what most business ethics offer today. Sebastian took his time to explain everything to me, top to bottom. Even the inner workings of how things worked on the backend. Thanks to Sebastian, I am now a paying customer with upstream. So far the stream quality has been improved and much easier to use than my previous setup. I highly recommend this service. I will update this review in a year from now. So far, A+ and better than anything else on the market!",
  },
  {
    name: 'Nolan Rempel',
    url: 'https://www.trustpilot.com/reviews/654f3c7858dccbf34fc3babf',
    rating: '5',
    review:
      "I'm completely brand-new to the nature of 24/7 livestreams. I needed something a bit specific, Sebastian and Dom were both HUGE helpers in explaining everything so I could easily understand it. On top of that, when tech problems arose that resulted in a stuttering stream, Dom and the support team worked quickly to rectify it, even though it was outside of the support window. I was honestly surprised, I've never seen ANY other company ever do that. Usually I'd have been stuck waiting until at least the next day. I think the customer support I've received has been among the best I have ever gotten to experience, and I commend the team for their fast work, and their utmost professionalism. I wish more companies would care as much as they seem to.",
  },
  {
    name: 'Nina Unrated',
    url: 'https://www.trustpilot.com/reviews/6531001d77917073aa8d572c',
    rating: '5',
    review:
      "I am brand new to stream a 24 seven I had the most amazing help from Dom & Mario!!! To be honest, their customer service is just as amazing as Apple! And everyone knows that I love Apple I am now currently running two streams 24 hours a day seven days a week if this goes exactly the way it's going now I am definitely going to upgrade!",
  },
  {
    name: 'Marc Ryan',
    url: 'https://www.trustpilot.com/reviews/64af150d0baa0c8c15743f5d',
    rating: '5',
    review:
      'results speak for themselves. since going live with upstream my stream got 10k views off an account with 900 followers. this tool helped me tap into a livestreaming market without going live by reusing old content. the support is also amazing i had a few issues with my stream keys (user error) and support emailed me before i even emailed them because they noticed i input something wrong. i just upgraded my plan so that i could add a 2nd stream because the account growth has been amazing.',
  },
  {
    name: 'Jordan - MrSpherical',
    url: 'https://www.trustpilot.com/reviews/651b3ee30e30fc8ab069eba0',
    rating: '5',
    review:
      'The upstream is amazing, and the stream designer is a really great tool! I can rest assured that the live stream is performing perfectly 24 hours a day, 7 days a week. We are seriously considering scaling up streams across all our channels. @MrSpherical',
  },
  {
    name: 'Paint Your Heaven LLC',
    url: 'https://www.trustpilot.com/reviews/659128f834d7713447d22df2',
    rating: '5',
    review: 'Just what we needed. AMAZING customer service as well!!',
  },
  {
    name: 'Heidy Cardens',
    url: 'https://www.trustpilot.com/reviews/64e3ad9d02c9b21f7efa8c87',
    rating: '5',
    review:
      'When I decided to run a Stream first I needed to how to do it, and I learned. Then I had to choose a trustworthy platform so I searched many of them, and among all the options I choose Upstream because offers an affordable investment, and has a very fast, kind, and professional team who cares about helping you!',
  },
  {
    name: 'Alister',
    url: 'https://www.trustpilot.com/reviews/648ad449b0259ab54787f95e',
    rating: '5',
    review:
      'Fantastic service, super helpful support team. Would highly recommend.',
  },
  {
    name: 'Alyssa Farris',
    url: 'https://www.trustpilot.com/reviews/6499748b4b712ac7584f064b',
    rating: '5',
    review:
      "I love how easy Upstream is to use - especially for someone like me who has no technical knowledge of servers. The whole process of a 24/7 live stream was overwhelming to me until I found upstream. Support is really responsive and it's clear they genuinely care about the platform and it's users.",
  },
]
</script>
