<template>
  <section
    class="relative dark:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] dark:from-grape-900 dark:via-grape-900 dark:to-black after:content-[''] after:h-1/5 after:bg-gradient-to-b after:from-transparent after:to-beige dark:after:to-black after:bottom-0 after:absolute after:left-0 after:right-0 after:pointer-events-none border-t border-opacity-10 border-white pb-20"
  >
    <div class="container px-4">
      <header class="pt-10 md:pt-20">
        <h2 class="text-3xl md:text-6xl mx-auto text-center">
          How live videos<br />
          compare to regular videos
        </h2>
        <p class="text-center md:text-xl mt-6">
          Don't take our word on it, let the numbers speak for themselves.
          <span class="md:block text-neutral-400"
            >Note: data based on a channel with roughly 2,500 subscribers.</span
          >
        </p>
      </header>

      <div
        class="flex overflow-scroll lg:overflow-visible lg:grid lg:grid-rows-2 lg:grid-cols-3 mt-8 lg:mt-20 gap-4 lg:gap-8"
      >
        <Card
          v-for="element in gridElements"
          :key="element.title"
          class="!bg-neutral-200 dark:!bg-black !border-neutral-300 dark:!border-neutral-800 !px-0 row-span-1 col-span-1 z-1 flex flex-col py-10 md:py-0 min-w-[320px]"
          :class="element.class"
        >
          <div class="my-auto">
            <div
              v-if="element.image"
              class="relative pb-8 before:content-[''] before:w-full before:h-1/2 before:bg-gradient-to-b dark:before:from-black before:to-transparent z-20 before:absolute"
              :class="element.imageWrapperClass"
            >
              <div class="relative z-20">
                <img
                  loading="lazy"
                  :src="`/images/bento/${element.image}`"
                  alt=""
                  class="block w-full z-10 relative"
                  :class="element.imageClass"
                />
                <img
                  v-if="!isDark"
                  loading="lazy"
                  :src="`/images/bento/${element.image}`"
                  alt=""
                  class="w-full h-full top-0 left-0 z-0 absolute blur-3xl opacity-70"
                  :class="element.imageClass"
                />
              </div>
            </div>
            <div :class="element.textWrapperClass">
              <h2
                class="font-unbounded text-center text-black dark:text-white text-3xl md:text-4xl"
                :class="element.titleClass"
                v-html="element.title"
              />
              <p
                class="text-center text-black dark:text-white text-lg md:text-2xl mt-1"
                :class="element.subtitleClass"
              >
                {{ element.subtitle }}
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </section>
</template>

<script setup>
import Card from '@/Components/Shared/Elements/Card.vue'
import { useDark } from '@vueuse/core'
const isDark = useDark()

const gridElements = [
  {
    title: '840k views',
    subtitle: '210% increase',
    image: 'eyes.svg',
    textWrapperClass: 'absolute right-8 bottom-8',
    titleClass: '!text-right',
    subtitleClass: '!text-right',
    imageWrapperClass: '-mt-28 w-96 md:mt-0 md:w-auto',
  },
  {
    title: '$500 <span class="md:text-3xl block">in profit</span>',
    subtitle: '694% increase',
    image: isDark ? 'stars-grape.svg' : 'stars.svg',
    class: '!row-span-2 !col-span-1',
    imageWrapperClass: 'w-1/2 mx-auto !pb-12',
    titleClass: 'md:!text-7xl',
  },
  {
    title: '96k hours<br/> watch time',
    subtitle: '400% increase',
    image: 'logo.svg',
    imageWrapperClass: 'w-44 mx-auto',
  },
  {
    title: '2000 new<br/> followers',
    subtitle: '500% increase',
    image: 'icons.svg',
    imageWrapperClass:
      '-mx-8 before:dark:before:!from-black before:!via-transparent before:dark:before:!to-black before:!bg-gradient-to-r before:!h-full',
  },
  {
    title: '$5.2<span class="md:text-3xl"> RPM</span>',
    subtitle: '325% increase',
    titleClass: 'md:!text-7xl',
    class: 'min-h-[300px]',
  },
]
</script>
