<script setup>
import Card from '@/Components/Shared/Elements/Card.vue'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

const emit = defineEmits(['onVideoClick'])

const isVideoVisible = ref(false)
const handlePosterClick = (value) => {
  isVideoVisible.value = value
  emit('onVideoClick', value)
}

const videoWrapper = ref()
onClickOutside(videoWrapper, () => handlePosterClick(false))
</script>

<template>
  <div class="z-50 relative">
    <kinesis-container>
      <div
        class="container relative px-4 group"
        @click="handlePosterClick(true)"
      >
        <kinesis-element
          class="absolute -bottom-4 lg:bottom-auto lg:top-1/3 -left-6 z-10 w-20 md:-left-24 md:w-auto"
          :strength="30"
          type="depth"
        >
          <img src="/images/doodles/eye.svg" class="-rotate-[30deg]" alt="" />
        </kinesis-element>
        <kinesis-element
          class="absolute -top-7 md:-top-20 -left-4 w-16 md:w-auto md:-left-16 z-10"
          :strength="20"
        >
          <img
            class="-rotate-[30deg]"
            src="/images/doodles/circle.svg"
            alt=""
          />
        </kinesis-element>
        <kinesis-element
          class="absolute w-20 md:w-auto -top-8 right-10 md:-top-16 z-10"
          :strength="-10"
        >
          <img src="/images/doodles/arrow.svg" alt="" />
        </kinesis-element>
        <div
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
        >
          <kinesis-element class="" :strength="10">
            <div
              class="group-hover:scale-105 transition-transform duration-300"
            >
              <CtaButton
                class="mt-2 !py-3 !px-6 lg:!py-6 lg:!px-9 lg:!text-xl ease-out cursor-pointer"
                type="span"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="w-8 lg:w-12 h-8 lg:h-12 mr-6 fill-current -ml-2"
                >
                  <path
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                  />
                </svg>
                Watch our 1 minute guide
              </CtaButton>
            </div>
          </kinesis-element>
        </div>
        <kinesis-element :strength="10">
          <Card class="!p-0 !border-neutral-600 -z-10 overflow-hidden">
            <img
              src="/images/builder/music_sm.webp"
              srcset="
                /images/builder/music_sm.webp  600w,
                /images/builder/music.webp    1440w
              "
              alt=""
              class="bg-black w-full z-10 object-cover transition-opacity group-hover:opacity-30 h-auto"
            />
          </Card>
        </kinesis-element>
      </div>
    </kinesis-container>
    <div
      v-if="isVideoVisible"
      class="fixed inset-0 z-20 backdrop-blur-xl bg-white/50 dark:bg-black/70 flex items-center justify-center"
    >
      <span
        class="absolute z-30 right-5 top-5 bg-white dark:bg-black/50 border rounded-md border-black/20 dark:border-white/20 p-2 font-semibold dark:text-white hover:!text-grape cursor-pointer"
      >
        <Icon name="X" class="w-5 h-5" />
      </span>
      <div ref="videoWrapper" class="container flex-1">
        <div class="w-full aspect-video relative z-20">
          <iframe
            class="w-full h-full absolute inset-0"
            width="1920"
            height="1080"
            src="https://www.youtube.com/embed/FO6jRyOvlAs?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
