<template>
  <div
    class="pt-2 lg:pt-20 relative dark:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] dark:from-grape-900 dark:via-grape-900 dark:to-black after:content-[''] after:h-1/6 lg:after:h-1/5 after:bg-gradient-to-b after:from-transparent after:to-white dark:after:to-black after:bottom-0 after:absolute after:left-0 after:right-0 after:pointer-events-none border-t border-opacity-10 border-white pb-20"
  >
    <div class="container">
      <div class="mx-4 lg:mx-0 flex my-8 items-center px-4 font-unbounded">
        <h3 class="lg:w-auto lg:text-xl font-normal">
          <span class="lg:block hidden">Our frequently given answers</span>
          <span class="lg:hidden">FAQ</span>
        </h3>
        <Link
          href="/faq"
          class="underlined decoration-wavy text-main dark:text-grape lg:text-xl ml-4 lg:ml-10 justify-end flex flex-1 hover:text-black transition-colors"
        >
          <span
            class="flex flex-1 before:content-[''] before:border items-center before:flex-1 before:border-current mr-5"
          >
            <svg
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          Read more
        </Link>
      </div>
      <div class="mx-auto px-4 lg:grid grid-cols-2 gap-4 lg:gap-8">
        <Card
          v-for="item in theWhy"
          :key="item.label"
          class="p-0 mb-4 lg:mb-0 flex dark:!bg-black dark:!border-neutral-800"
        >
          <div class="p-8 lg:p-16 flex flex-col relative overflow-hidden">
            <h3 class="lg:text-xl font-medium mb-4 lg:mb-2">
              {{ item.label }}
            </h3>
            <p class="text-lg leading-relaxed font-body font-medium mt-4">
              <span class="flex-1" v-html="item.content" />
            </p>
            <CtaButton
              type="external"
              class="mt-8 self-start"
              :href="item.url"
              target="_blank"
            >
              {{ item.more }}
            </CtaButton>
          </div>
        </Card>
      </div>
      <div class="lg:mt-8 px-4">
        <ul
          class="lg:mt-4 text-lg leading-relaxed font-body font-medium grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 text-neutral-700 dark:text-white"
        >
          <li v-for="content in benefits" :key="content">
            <Card class="h-full dark:!bg-black dark:!border-neutral-800">
              <span class="mb-6 block text-grape">
                <Icon class="w-8 h-8" :name="content.icon" />
              </span>
              <span class="flex-1" v-html="content.label"></span>
            </Card>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from '@/Components/Shared/Elements/Card.vue'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'

const listClass = `flex items-center before:text-neutral-500 before:w-6 before:h-6 before:block before:rounded-full before:border before:border-neutral-300 before:items-center before:justify-center before:flex before:text-xs before:mr-3 [counter-increment:faq-counter] before:[content:counter(faq-counter)]`

const theWhy = [
  {
    label: 'How To Live Stream 24/7 on YouTube?',
    content: `To live stream 24/7 on YouTube, you need to follow a few key steps:
     <ol class="mt-2 block leading-loose [counter-reset:faq-counter]">
      <li class="${listClass}"><span class="flex-1">Create a YouTube channel</span></li>
      <li class="${listClass}"><span class="flex-1">Connect Upstream.so and YouTube with your Stream key</span></li>
      <li class="${listClass}"><span class="flex-1">Upload videos & design your live stream</span></li>
      <li class="${listClass}"><span class="flex-1">Start your 24/7 live stream</span></li>
    </ol>`,
    more: 'Step by step guide',
    url: 'https://upstream.so/blog/how-to-set-up-24-7-live-stream-on-youtube/',
  },
  {
    label: 'How To Find your YouTube Stream Key?',
    content: `To find your YouTube stream key, follow these steps:
    <ol class="mt-2 block leading-loose [counter-reset:faq-counter]">
      <li class="${listClass}"><span class="flex-1">Navigate to YouTube Studio</span></li>
      <li class="${listClass}"><span class="flex-1">Select the <strong class="font-bold">Go Live</strong> option under the <strong class="font-bold">Create</strong> dropdown button</span></li>
      <li class="${listClass}"><span class="flex-1">Click <strong class="font-bold">Stream &rarr; Stream settings</strong> to find your stream key</span></li>
      <li class="${listClass}"><span class="flex-1">Copy and paste your stream key into Upstream.so</span></li>
    </ol>`,
    more: 'Find your stream key',
    url: 'https://upstream.so/blog/how-to-easily-find-your-youtube-stream-key-step-by-step/',
  },
]

const benefits = [
  {
    label: 'Best in class drag & drop stream designer',
    icon: 'LayoutDashboard',
  },
  { label: 'Multiple channels for music and effects', icon: 'Shuffle' },
  { label: 'Upload pre-recorded videos and queue them', icon: 'ListStart' },
  { label: 'Live update stream without ever stopping it', icon: 'Radio' },
  {
    label: '24/7 Live support available for assistance',
    icon: 'MessageCircle',
  },
  { label: 'No technical knowledge required', icon: 'Sticker' },
  { label: 'No server setup and maintenance', icon: 'Server' },
  { label: 'Schedule your streams in advance', icon: 'CalendarClock' },
]
</script>
